<template>
  <b-container fluid class="p-0">
    <checklist-list
      :drive-car-id="driverCarId"
      :queryType="queryType"
    />
  </b-container>
</template>

<script>
import ChecklistList from '@components/shared/checklist/checklist-list';
export default {
  name: 'Checklist',
  data () {
    return {}
  },
  components: {
    ChecklistList
  },
  computed: {
    driverCarId () {
      return this.$route.params.id
    },
    queryType () {
      return this.$route.meta.queryType
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
