var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      _c("checklist"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }