var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-3", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "checklist__list" },
        [
          _c(
            "b-col",
            { attrs: { xs: "12", sm: "12", md: "12" } },
            [
              _c("custom-data-table", {
                ref: "checklistDataTable",
                attrs: {
                  fields: _vm.fields,
                  filters: _vm.filters,
                  "fixed-filters": _vm.queryVariable,
                  "query-node": "checklists",
                  "query-context": "Checklist",
                  query: _vm.checklist_list_query,
                  sort: [["created_at", "DESC"]],
                  "query-variables": {},
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "kind",
                      fn: function (props) {
                        return _c("span", {}, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getKindWithAdditionalDetails(props.rowData)
                              ),
                            },
                          }),
                        ])
                      },
                    },
                    {
                      key: "place",
                      fn: function (props) {
                        return _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getFormattedPlaceDisplay(props.rowData)
                            ),
                          },
                        })
                      },
                    },
                    {
                      key: "created_at",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDateUtc(props.rowData.created_at)
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: _vm.carOrDriver,
                      fn: function (props) {
                        return _c("span", {}, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-link p-0 mt-1 font-weight-bold",
                              on: {
                                click: function ($event) {
                                  return _vm.goDriverOrCar(
                                    props.rowData[_vm.carOrDriver]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatCarOrDriver")(
                                      props.rowData[_vm.carOrDriver],
                                      _vm.carOrDriver
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      },
                    },
                    {
                      key: "show",
                      fn: function (props) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                title: _vm.$t("checklist.buttons.edit"),
                                variant: "success",
                                disabled: !_vm.isValidKind(props.rowData.kind),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showChecklist(props.rowData)
                                },
                              },
                            },
                            [_vm._v("Visualizar ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }