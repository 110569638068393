var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _c("checklist-list", {
        attrs: { "drive-car-id": _vm.driverCarId, queryType: _vm.queryType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }