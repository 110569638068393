<template>
  <layout>
    <fleet-header :id="$route.params.id" />
    <checklist />
  </layout>
</template>

<script>
import Layout from '@layouts/main';
import FleetHeader from '@components/fleet/header';
import Checklist from '@components/shared/checklist/checklist';
export default {
  name: 'DriverChecklist',
  data () {
    return {}
  },
  components: {
    Layout,
    FleetHeader,
    Checklist
  }
};
</script>
