<template>
  <b-container fluid class="mt-3">
    <b-row
      class="checklist__list"
    >
      <b-col
        xs="12"
        sm="12"
        md="12"
      >
        <custom-data-table
          ref="checklistDataTable"
          :fields="fields"
          :filters="filters"
          :fixed-filters="queryVariable"
          query-node="checklists"
          query-context="Checklist"
          :query="checklist_list_query"
          :sort="[['created_at', 'DESC']]"
          :query-variables="{}"
        >

          <span slot="kind" slot-scope="props">
            <div v-html="getKindWithAdditionalDetails(props.rowData)"></div>
          </span>

          <span slot="place" slot-scope="props" v-html="getFormattedPlaceDisplay(props.rowData)"></span>

          <span slot="created_at" slot-scope="props">
            {{ formatDateUtc(props.rowData.created_at) }}
          </span>

          <span :slot="carOrDriver" slot-scope="props">
            <button
              @click="goDriverOrCar(props.rowData[carOrDriver])"
              class="btn btn-link p-0 mt-1 font-weight-bold"
            >
              {{ props.rowData[carOrDriver] | formatCarOrDriver(carOrDriver) }}
            </button>
          </span>

          <template slot="show" slot-scope="props">
            <b-button
              :title="$t('checklist.buttons.edit')"
              variant="success"
              :disabled="!isValidKind(props.rowData.kind)"
              @click.prevent="showChecklist(props.rowData)"
            >Visualizar
            </b-button>
          </template>

        </custom-data-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { VALID_CHECKLIST_KIND } from '@enums/checklist';
import CHECKLIST_LIST from '@graphql/checklist/queries/list.gql';
import CustomDataTable from '@components/CustomDataTable';
import { isBrazil } from '@utils/helper-region'
import { enumChecklistKind } from '@utils/checklist';


export default {
  name: 'ChecklistList',
  props: {
    driveCarId: {
      type: String,
      required: true
    },
    queryType: {
      type: String,
      required: true,
      default: 'driver',
      validator: (value) => {
        return ['driver', 'car']
      }
    }
  },
  components: {
    CustomDataTable
  },
  data: () => ({
    translatedEnum: null,
    checklist_list_query: CHECKLIST_LIST,
  }),
  created() {
    this.translatedEnum = this.translateEnumChecklistKind(enumChecklistKind);
  },
  computed: {
    filters() {
      return {
        kind: {
          type: 'select',
          cols: 3,
          props: {
            label: this.$t('checklist.labels.kind'),
            placeholder: this.$t('checklist.labels.kind'),
            options: [{
                value: null,
                text: this.$t('checklist.kinds.ALL')
              },
              ...this.translatedEnum
            ],
          },
          graphqlQuery(value) {
            if (value === null) return;
            return {
              kind: value
            };
            }
        },
      }
    },
    fields () {
      return [
        {
          name: this.carOrDriver,
          title: this.$t(`checklist.labels.${this.carOrDriver}`),
        },
        {
          name: 'kind',
          title: this.$t('checklist.labels.kind'),
        },
        {
          name: 'place',
          title: this.$t('checklist.labels.place'),
        },
        {
          name: 'assigned_by',
          title: this.$t('checklist.labels.assignedBy'),
        },
        {
          name: 'created_at',
          title: this.$t('checklist.labels.createdAt'),
        },
        {
          name: 'show',
          title: '',
        }
      ]
    },
    carOrDriver () {
      return (this.queryType === 'driver') ? 'car' : 'driver'
    },
    queryVariable () {
      let type = this.queryType
      let variableObj = {
        [type]: ''
      }

      variableObj[type] = this.driveCarId

      return variableObj
    }
  },
  filters: {
    formatCarOrDriver (item, type) {
      let field = 'name'
      let currentLicensePlate = ''

      if (type === 'car') field = 'license_plate'

      if (item) currentLicensePlate = item[field]

      return currentLicensePlate
    }
  },
  methods: {
    translateEnumChecklistKind(enumData) {
      return enumData.map(item => ({
        ...item,
        text: this.$t(`checklist.kinds.${item.text}`),
      }));
    },
    formatDateUtc (date) {
      const formater = isBrazil() ? 'DD/MM/YYYY HH:mm' : 'YYYY/MM/DD HH:mm'

      const timeZone = isBrazil() ? 'America/Sao_Paulo' : 'America/Mexico_City'

      return this.$moment(date).tz(timeZone).format(formater)
    },
    toCamelCase(str) {
        return str
            .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },
    isValidKind (kind) {
      return VALID_CHECKLIST_KIND.includes(kind)
    },
    showChecklist (currentChecklist) {
      const checklistId = currentChecklist.id
      const checklistKind = currentChecklist.kind

      if (this.isValidKind(checklistKind)) window.open(`https://checklist.kovi.com.br/${checklistId}`, '_blank');
    },
    goDriverOrCar (item) {
      const currentUrl = (this.queryType === 'driver') ? 'fleet' : 'drivers/profile'
      const id = (item && item.id) ? item.id : ''

      this.$router.push({ path: `/${currentUrl}/${id}`})
    },
    getKindWithAdditionalDetails(rowData) {
      const kind = rowData.kind;
      const translationKeyKind = `checklist.kinds.${kind}`;

      const kindDetails = {
        'PREVENTIVE_MAINTENANCE': 'visit_reason',
        'QUALITY_CONTROL': 'reason_return',
        'CLOSE_BOOKING': 'reason_return'
      }

      const detailKey = kindDetails[kind]
      const detailValue = rowData.checklist?.[detailKey]

      if (detailKey && detailValue) {
        const translationDetailKey = `checklist.${this.toCamelCase(detailKey)}.${detailValue}`
        return `${this.$t(translationKeyKind)} <br> ${this.$t(translationDetailKey)}`;
      }

      return this.$t(translationKeyKind);
    },
    getFormattedPlaceDisplay(rowData) {
      const kind = rowData.kind;
      let placeValue =  rowData.checklist?.place || '';

      const kindFunctionMap = {
        'INVENTORY': this.getDisplayValueForInventory,
        'MOVIMENTATION': this.getDisplayValueForMovimentation,
        'PREVENTIVE_MAINTENANCE': this.getDisplayValueForPreventiveMaintenance,
      };

      const placeFunction =  kindFunctionMap[kind];

      return placeFunction ? placeFunction(placeValue, rowData): placeValue;
    },
    getDisplayValueForInventory(displayValue, rowData) {
      if (rowData.checklist?.parking_lot) {
        displayValue += `<br><br>${rowData.checklist.parking_lot}`;
      }
      if (rowData.checklist?.observation) {
        displayValue += `<br><br>OBS: ${rowData.checklist.observation}`;
      }
      return displayValue;
    },
    getDisplayValueForMovimentation(displayValue, rowData) {
      const carLocation = rowData.checklist.car_location;
      const translationKeyCarLocation = `checklist.carLocation.${carLocation}`;
      displayValue += ` (${this.$t(translationKeyCarLocation)})`;
      return displayValue;
    },
    getDisplayValueForPreventiveMaintenance(displayValue, rowData) {
      const processType = rowData.checklist?.process_type;
      if (!processType) {
        return displayValue;
      }
      const translationKeyProcessType= `checklist.processType.${processType}`;
      displayValue += ` (${this.$t(translationKeyProcessType)})`;
      return displayValue;
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
