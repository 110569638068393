export const enumChecklistKind = [
    {
      text: 'QUALITY_CONTROL',
      value: 'QUALITY_CONTROL'
    },
    {
        text: 'INVENTORY',
        value: 'INVENTORY'
    },
    {
        text: 'CLOSE_BOOKING',
        value: 'CLOSE_BOOKING'
    },
    {
        text: 'WELCOME',
        value: 'WELCOME'
    },
    {
        text: 'START_BOOKING',
        value: 'START_BOOKING'
    },
    {
        text: 'FLEET_ONBOARDING',
        value: 'FLEET_ONBOARDING'
    },
    {
        text: 'FLEET_ARRIVAL',
        value: 'FLEET_ARRIVAL'
    },
    {
      text: 'DIAGNOSIS',
      value: 'DIAGNOSIS'
    },
    {
        text: 'PREVENTIVE_MAINTENANCE',
        value: 'PREVENTIVE_MAINTENANCE'
    },
    {
        text: 'MOVIMENTATION',
        value: 'MOVIMENTATION'
    },
    {
        text: 'INSURANCE',
        value: 'INSURANCE'
    },
];